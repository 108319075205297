import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ProgressBar, Space, Size } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { P, Link, ComponentLinks } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "progressbar"
    }}>{`ProgressBar`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { ProgressBar } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=3508%3A50'} storybook={'/?path=/story/progressbar-progressbar--playground'} vueStorybook={'/?path=/story/progressbar-progressbar--playground'} name={'ProgressBar'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`ProgressBar — это компонент для создания полосы прогресса.`}</p>
    <p>{`Значение прогресса задается пропом `}<inlineCode parentName="p">{`value`}</inlineCode>{`. `}<inlineCode parentName="p">{`value`}</inlineCode>{` – число от `}<inlineCode parentName="p">{`0`}</inlineCode>{` до `}<inlineCode parentName="p">{`100`}</inlineCode>{`. Любое число меньше `}<inlineCode parentName="p">{`0`}</inlineCode>{` считается равным `}<inlineCode parentName="p">{`0`}</inlineCode>{`, любое число больше `}<inlineCode parentName="p">{`100`}</inlineCode>{` - равным `}<inlineCode parentName="p">{`100`}</inlineCode>{`.`}</p>
    <p>{`Если прогресс неизвестен, используйте `}<inlineCode parentName="p">{`indeterminate`}</inlineCode>{` состояние. В данном состоянии показывается бесконечная загрузка.`}</p>
    <p>{`У ProgressBar два размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{` и `}<inlineCode parentName="p">{`medium`}</inlineCode>{`. По умолчанию используется `}<inlineCode parentName="p">{`medium`}</inlineCode>{`. Передайте в проп size нужный размер`}</p>
    <p>{`Если вам нужна кнопка отмены, лейбл или описание – используйте компонент `}<a parentName="p" {...{
        "href": "/src-components-labeled-progress-bar"
      }}>{`LabeledProgressBar`}</a></p>
    <Playground __position={1} __code={'<ProgressBar value={50} />'} __scope={{
      props,
      DefaultLayout,
      ProgressBar,
      Space,
      Size,
      Playground,
      Props,
      P,
      Link,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ProgressBar value={50} mdxType="ProgressBar" />
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "размер"
    }}>{`Размер`}</h3>
    <p>{`У ProgressBar два размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{` и `}<inlineCode parentName="p">{`medium`}</inlineCode>{`. По умолчанию `}<inlineCode parentName="p">{`medium`}</inlineCode>{`. Передайте в проп size нужный`}</p>
    <Playground __position={2} __code={'<ProgressBar value={50} size=\"small\" />\n<Space size={Size.small_2x} vertical />\n<ProgressBar value={50} />'} __scope={{
      props,
      DefaultLayout,
      ProgressBar,
      Space,
      Size,
      Playground,
      Props,
      P,
      Link,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ProgressBar value={50} size="small" mdxType="ProgressBar" />
  <Space size={Size.small_2x} vertical mdxType="Space" />
  <ProgressBar value={50} mdxType="ProgressBar" />
    </Playground>
    <h3 {...{
      "id": "неопределенное-состояние"
    }}>{`Неопределенное состояние`}</h3>
    <p>{`Если прогресс неизвестен, используйте состояние `}<inlineCode parentName="p">{`indeterminate`}</inlineCode>{`. В данном состоянии показывается бесконечная загрузка`}</p>
    <Playground __position={3} __code={'<ProgressBar indeterminate />'} __scope={{
      props,
      DefaultLayout,
      ProgressBar,
      Space,
      Size,
      Playground,
      Props,
      P,
      Link,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <ProgressBar indeterminate mdxType="ProgressBar" />
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={ProgressBar} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на корневой элемент `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      